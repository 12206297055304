import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import firebase from "firebase/compat/app"; // Firebase compat
import router from "./router";
import store from "./store";
import App from "./App.vue";
import firebaseConfig from "./firebase/firebaseConfig"; // Your Firebase configuration
import "./global-components"; // Global Vue components
import "firebase/compat/auth";
// Import 3rd party plugins and libraries
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/vue-select";

// Initialize Firebase with your configuration
const userEmail = localStorage.getItem("userEmail");
if (userEmail) {
  store.commit("setUserEmail", userEmail);
}
firebase.initializeApp(firebaseConfig);
firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

// Register BootstrapVue plugins
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Use Vue Composition API
Vue.use(VueCompositionAPI);

// Import core styles
require("@core/scss/core.scss");

// Import assets styles
require("@/assets/scss/style.scss");

// Disable Vue's production tip
Vue.config.productionTip = false;

// Create a new Vue instance and mount it to the DOM
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
